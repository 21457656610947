import React, { useEffect, useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import marked from "marked"
import PageLayout from "../components/hbnfpagelayout"
import Head from "../components/head"
import speakerimg from "../images/speaker.png"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      signUpForUpdatesUrl
      registrationUrl
      registrationCtaText
      transparentHeader
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      speakerDetails {
        status
        statusNote {
          statusNote
        }
        callForSpeakersUrl
        callForSpeakersDetails {
          callForSpeakersDetails
        }
        keynoteSpeakers {
          name
          designation
          organisation
          bio {
            bio
          }
          image {
            file {
              url
            }
          }
        }
        speakers {
          name
          designation
          organisation
          bio {
            bio
          }
          image {
            file {
              url
            }
          }
        }
        sponsoredSessionSpeakers {
          name
          designation
          organisation
          bio {
            bio
          }
          image {
            file {
              url
            }
          }
        }
        seoMetadata {
          title
          description {
            description
          }
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`

const Speakers = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [selectedSpeaker, setSelectedSpeaker] = useState(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })
  const speakerPopupOpen = speaker => () => {
    setSelectedSpeaker(speaker)
    document.getElementById("event_popup").style.display = "flex"
  }
  const speakerPopupClose = () => {
    document.getElementById("event_popup").style.display = "none"
  }

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.target.id === "event_popup") {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("mousedown", close)
    return () => window.removeEventListener("mousedown", close)
  }, [])

  const getMarkdownText = callForSpeakersDetails => {
    if (
      callForSpeakersDetails &&
      (callForSpeakersDetails !== null || callForSpeakersDetails !== undefined)
    ) {
      var rawMarkup = marked(callForSpeakersDetails, {
        sanitize: true,
        breaks: true,
      })
      return { __html: rawMarkup }
    }
  }

  useLayoutEffect(() => {
    function loadScript() {
      var e = document.createElement("script")
      window.bz_widget_env = "https://events.bizzabo.com"
      e.type = "text/javascript"
      e.async = !0
      e.src = "https://organizer.bizzabo.com/widgets/sections/sections.js"
      var t = document.getElementsByTagName("script")[0]
      t.parentNode.insertBefore(e, t)
    }
    loadScript()
  }, [])

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      signUpUrl={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={
          props?.data?.contentfulEvents?.speakerDetails?.seoMetadata?.title
        }
        description={
          props?.data?.contentfulEvents?.speakerDetails?.seoMetadata
            ?.description?.description
        }
      />
      {props?.data?.contentfulEvents?.slug === "kafka-summit-london-2024" && (
        <div className="speaker_body_bizz">
          {props?.data?.contentfulEvents?.slug ===
            "kafka-summit-london-2024" && (
            <div
              class="bizzabo-sections-widget"
              data-unique-name="559905"
              data-tab-id="3412684"
              data-internal-website-base-url="https://events.bizzabo.com/559905"
            ></div>
          )}
        </div>
      )}
      {props?.data?.contentfulEvents?.slug ===
        "kafka-summit-bangalore-2024" && (
        <div
          class="bizzabo-sections-widget"
          data-unique-name="573863"
          data-tab-id="3533235"
          data-internal-website-base-url="https://events.bizzabo.com/573863"
        ></div>
        // <div className="speaker_body">
        //   {props?.data?.contentfulEvents?.slug ===
        //     "kafka-summit-bangalore-2024" && (
        //     <>
        //       {props?.data?.contentfulEvents?.speakerDetails?.status ===
        //         "Not Available" &&
        //         props?.data?.contentfulEvents?.speakerDetails?.statusNote
        //           ?.statusNote && (
        //           <div className="speaker_title spk_center">
        //             {
        //               props?.data?.contentfulEvents?.speakerDetails?.statusNote
        //                 ?.statusNote
        //             }
        //           </div>
        //         )}
        //       {(props?.data?.contentfulEvents?.speakerDetails?.status ===
        //         "Closed" ||
        //         props?.data?.contentfulEvents?.speakerDetails?.status ===
        //           "Open") && (
        //         <>
        //           <div className="speaker_title ">
        //             Call For Papers Is{" "}
        //             {props?.data?.contentfulEvents?.speakerDetails?.status ===
        //             "Closed"
        //               ? "Closed"
        //               : "Open"}
        //           </div>
        //           {props?.data?.contentfulEvents?.speakerDetails
        //             ?.statusNote && (
        //             <div
        //               style={{ whiteSpace: "pre-wrap" }}
        //               dangerouslySetInnerHTML={getMarkdownText(
        //                 props?.data?.contentfulEvents?.speakerDetails
        //                   ?.statusNote?.statusNote
        //               )}
        //             ></div>
        //           )}
        //         </>
        //       )}

        //       {props?.data?.contentfulEvents?.speakerDetails?.status ===
        //         "Open" && (
        //         <div
        //           className="call_for_speaker_details"
        //           // style={{ whiteSpace: "pre-wrap" }}
        //           dangerouslySetInnerHTML={getMarkdownText(
        //             props?.data?.contentfulEvents?.speakerDetails
        //               ?.callForSpeakersDetails?.callForSpeakersDetails
        //           )}
        //         ></div>
        //       )}

        //       {props?.data?.contentfulEvents?.speakerDetails?.status ===
        //         "Open" && (
        //         <a
        //           className="call_to_speaker_link"
        //           target="_blank"
        //           href={
        //             props?.data?.contentfulEvents?.speakerDetails
        //               ?.callForSpeakersUrl
        //           }
        //         >
        //           SUBMIT YOUR TALK
        //         </a>
        //       )}
        //     </>
        //   )}
        //   {/* {props?.data?.contentfulEvents?.speakerDetails?.keynoteSpeakers && (
        //   <>
        //     <div className="speaker_body_title">Keynote Speakers</div>
        //     <div className="speaker_thumbs">
        //       {props?.data?.contentfulEvents?.speakerDetails?.status ===
        //         "Filled" &&
        //         props?.data?.contentfulEvents?.speakerDetails?.keynoteSpeakers?.map(
        //           (item, index) => {
        //             return (
        //               <div
        //                 className="speaker_thumb"
        //                 key={index}
        //                 onClick={speakerPopupOpen(item)}
        //               >
        //                 <div className="speaker_photo">
        //                   <img
        //                     src={
        //                       item?.image?.file?.url
        //                         ? item?.image?.file?.url
        //                         : speakerimg
        //                     }
        //                     alt="speakerimage"
        //                   />
        //                 </div>
        //                 <div className="speaker_data">
        //                   <div className="speaker_name">{item?.name}</div>
        //                   <div className="speaker_info">
        //                     {item?.designation && <>{item?.designation},</>}{" "}
        //                     {item?.organisation && <>{item?.organisation}</>}
        //                   </div>
        //                 </div>
        //               </div>
        //             )
        //           }
        //         )}
        //     </div>
        //   </>
        // )}

        // {props?.data?.contentfulEvents?.speakerDetails?.speakers && (
        //   <>
        //     <div className="speaker_body_title">
        //       Breakout Session & Lightning Talk Speakers
        //     </div>
        //     <div className="speaker_thumbs">
        //       {props?.data?.contentfulEvents?.speakerDetails?.status ===
        //         "Filled" &&
        //         props?.data?.contentfulEvents?.speakerDetails?.speakers?.map(
        //           (item, index) => {
        //             return (
        //               <div
        //                 className="speaker_thumb"
        //                 key={index}
        //                 onClick={speakerPopupOpen(item)}
        //               >
        //                 <div className="speaker_photo">
        //                   <img
        //                     src={
        //                       item?.image?.file?.url
        //                         ? item?.image?.file?.url
        //                         : speakerimg
        //                     }
        //                     alt="speakerimage"
        //                   />
        //                 </div>
        //                 <div className="speaker_data">
        //                   <div className="speaker_name">{item?.name}</div>
        //                   <div className="speaker_info">
        //                     {item?.designation && <>{item?.designation},</>}{" "}
        //                     {item?.organisation && <>{item?.organisation}</>}
        //                   </div>
        //                 </div>
        //               </div>
        //             )
        //           }
        //         )}
        //     </div>
        //   </>
        // )}

        // {props?.data?.contentfulEvents?.speakerDetails
        //   ?.sponsoredSessionSpeakers && (
        //   <>
        //     <div className="speaker_body_title">Sponsored Session Speakers</div>
        //     <div className="speaker_thumbs">
        //       {props?.data?.contentfulEvents?.speakerDetails?.status ===
        //         "Filled" &&
        //         props?.data?.contentfulEvents?.speakerDetails?.sponsoredSessionSpeakers?.map(
        //           (item, index) => {
        //             return (
        //               <div
        //                 className="speaker_thumb"
        //                 key={index}
        //                 onClick={speakerPopupOpen(item)}
        //               >
        //                 <div className="speaker_photo">
        //                   <img
        //                     src={
        //                       item?.image?.file?.url
        //                         ? item?.image?.file?.url
        //                         : speakerimg
        //                     }
        //                     alt="speakerimage"
        //                   />
        //                 </div>
        //                 <div className="speaker_data">
        //                   <div className="speaker_name">{item?.name}</div>
        //                   <div className="speaker_info">
        //                     {item?.designation && <>{item?.designation},</>}{" "}
        //                     {item?.organisation && <>{item?.organisation}</>}
        //                   </div>
        //                 </div>
        //               </div>
        //             )
        //           }
        //         )}
        //     </div>
        //   </>
        // )} */}
        // </div>
      )}
      {/* <div className="event_popup" id="event_popup">
        <div className="event_popup_body">
          <div className="event_popup_close">
            <span onClick={speakerPopupClose}>X</span>
          </div>
          <div className="event_popup_speaker">
            <div className="speaker_photo">
              <img
                src={
                  selectedSpeaker?.image?.file?.url
                    ? selectedSpeaker?.image?.file?.url
                    : speakerimg
                }
                alt="speakerheadshot"
              />
            </div>
            <div className="speaker_data">
              <div className="speaker_name">{selectedSpeaker?.name}</div>
              <div className="speaker_info">
                {selectedSpeaker?.designation && (
                  <>{selectedSpeaker?.designation},</>
                )}{" "}
                {selectedSpeaker?.organisation && (
                  <>{selectedSpeaker?.organisation}</>
                )}
              </div>
            </div>
          </div>
          <div
            className="event_popup_info"
            style={{ whiteSpace: "pre-wrap", marginTop: "15px" }}
          >
            {selectedSpeaker?.bio?.bio}
          </div>
        </div>
      </div> */}
    </PageLayout>
  )
}
export default Speakers
